// extracted by mini-css-extract-plugin
export var column = "UserNetworkMplsProject__column__TJcV8";
export var container = "UserNetworkMplsProject__container__EusJh";
export var contentWrapper = "UserNetworkMplsProject__contentWrapper__RiqFT";
export var flex = "UserNetworkMplsProject__flex__gmOtZ";
export var flexColumn = "UserNetworkMplsProject__flexColumn__vGGd8";
export var gap1 = "UserNetworkMplsProject__gap1__lBcDh";
export var gap2 = "UserNetworkMplsProject__gap2__tMqIq";
export var gap3 = "UserNetworkMplsProject__gap3__HwglV";
export var gap4 = "UserNetworkMplsProject__gap4__lxrG0";
export var gap5 = "UserNetworkMplsProject__gap5__XHyll";
export var row = "UserNetworkMplsProject__row__QY8CN";