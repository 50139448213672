// extracted by mini-css-extract-plugin
export var colLeft = "UserNetworkMplsProject_Details__colLeft__xf7aw";
export var colRight = "UserNetworkMplsProject_Details__colRight__Qkt7J";
export var column = "UserNetworkMplsProject_Details__column__WvjRn";
export var columnWrapper = "UserNetworkMplsProject_Details__columnWrapper__yi4kH";
export var descriptionContent = "UserNetworkMplsProject_Details__descriptionContent__Nr8Mb";
export var descriptionHeading = "UserNetworkMplsProject_Details__descriptionHeading__ALq8o";
export var flex = "UserNetworkMplsProject_Details__flex__qbVNM";
export var flexColumn = "UserNetworkMplsProject_Details__flexColumn__cvtm8";
export var gap1 = "UserNetworkMplsProject_Details__gap1__nXKXh";
export var gap2 = "UserNetworkMplsProject_Details__gap2__bV1yZ";
export var gap3 = "UserNetworkMplsProject_Details__gap3__RJe41";
export var gap4 = "UserNetworkMplsProject_Details__gap4___tgBJ";
export var gap5 = "UserNetworkMplsProject_Details__gap5__zsTDP";
export var lastUpdated = "UserNetworkMplsProject_Details__lastUpdated__yAMbJ";
export var reviewStatusWarning = "UserNetworkMplsProject_Details__reviewStatusWarning__jf98p";
export var row = "UserNetworkMplsProject_Details__row__yJRM0";